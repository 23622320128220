import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosInstance from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  msgListLoading: false,
  msgSearchLoading: false,
  isChatListLoading: false,
  error: false,
  chatList: [],
  singleChat: null,
  sortBy: null,
  chatFilters: [],
  CATEGORY_OPTION: [],
  DOMAIN_OPTION: [],
  SUBDOMAIN_OPTION: [],
  DOCUMENT_OPTION: [],
  conversation: [],
  filterMapping: {},
  wikiData: {},
  filterWikiData: [],
  searchWikiData: [],
  compareDocs:[],
  wikiFilters:{
    category: [],
    domain:  [],
    subDomain:  [],
  },
};

const slice = createSlice({
  name: 'wiki',
  initialState,
  reducers: {
    // LOADING
    updateLoadingState(state,payload) {
      state.isLoading = payload;
    },

    // START LOADING
    startChatRespLoading(state) {
      state.msgListLoading = true;
    },

    // START LOADING
    startChatListLoading(state) {
      state.isChatListLoading = true;
    },

    // START LOADING
    startChatSearchLoading(state) {
      state.msgSearchLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },


    // GET Single Chat


    handleClearFilter: (state, { payload }) => {
      state.chatFilters = { category: ['Guidance'] };
    },

    // chat filters
    handleChatFilters: (state, { payload }) => {
      state.chatFilters = payload;
    },

    // chat selected
    handleSelectedChat: (state, { payload }) => {
      state.selectedChatId = payload;
    },

    setFilterMappingSuccess: (state, action) => {
      const documentsData = action.payload; // The raw data from your API

      // Initialize structures for categories, domains, subdomains, and documents

      const finalCategories = {}
      const finalDomains = {};
      const finalSubDomains = {};
      const documentsMapping = {}; // Updated to include direct domain-document mapping
      const domainDocumentsMapping = {}; // New mapping for domains to documents
      const reverseMapping = {
        // Reverse mapping for documents to all parent filters
        subDomainsToDomains: {},
        domainsToCategories: {}
      };

      Object.entries(documentsData).forEach(([docName, docDetails]) => {
        const {
          'Document Category': category,
          'Document Sub-Domain': domain,
          'Sub-Domains': subDomain,
          Reference: document
        } = docDetails;

        // Populate categories
        // categories.add(category);
        function repeat(x,obj) { obj[x] = (obj[x] || 0) + 1; }

        // Populate categories
        repeat(category,finalCategories)
  
        // Populate domains
        if (!finalDomains[category]) {
          finalDomains[category] = {};
        }
        repeat(domain,finalDomains[category])

       

        // Populate subdomain mappings
        if (!finalSubDomains[domain]) {
          finalSubDomains[domain] = {};
        }
        repeat(subDomain,finalSubDomains[domain])
        // Populate reverse mappings
        // reverseMapping.subDomainsToDomains[subDomain] = domain;

        if (reverseMapping.subDomainsToDomains[subDomain]) {
          reverseMapping.subDomainsToDomains[subDomain].push(domain);
      } else {
          reverseMapping.subDomainsToDomains[subDomain] = [domain];
      }
  

        reverseMapping.domainsToCategories[domain] = category;
      });

      const finalDocumentsMapping = Object.fromEntries(
        Object.entries(documentsMapping).map(([key, value]) => [key, Array.from(value)])
      );
      const finalDomainDocumentsMapping = Object.fromEntries(
        Object.entries(domainDocumentsMapping).map(([key, value]) => [key, Array.from(value)])
      );

      function objectToArray(obj) {
        return Object.keys(obj || {}).map(key => ({ [key]: obj[key] }));
    }

      // Store mappings in state
      state.filterMapping = {
        categories: objectToArray(finalCategories),
        domains: finalDomains,
        subDomainsMapping: finalSubDomains,
        documentsMapping: finalDocumentsMapping,
        domainDocumentsMapping: finalDomainDocumentsMapping, // Include the new domain-documents mapping
        reverseMapping // Store the reverse mapping in the state
      };
    },

    setWikiData: (state, { payload }) => {
      state.isLoading = true
      state.wikiData = Object.values(payload);
      state.isLoading = false
    },

    updateCompareDocs: (state, { payload }) => {
      if(state.compareDocs.some(doc => doc.summary.title == payload.summary.title)){
        state.compareDocs = state.compareDocs.filter(doc => doc.summary.title !== payload.summary.title)
      }else{
        state.compareDocs = [...state.compareDocs,payload];
      }
    },

    updateWikiFilters: (state, { payload }) => {
      state.wikiFilters = payload;
    },

    setFilteredWikiData: (state, { payload = [] }) => {
        state.isLoading = true
        if(payload.length > 0){
            state.filterWikiData = payload;
        }
        state.isLoading = false
    },

    setSearchedWikiData: (state, { payload = [] }) => {
        state.isLoading = true
        if(payload.length > 0){
            state.searchWikiData = payload;
            
        }
        state.isLoading = false
    },
    updateFavouriteStatus(state, action) {
      state.favouriteStatus = action.payload;
    },
    getUserFavouriteDocs(state, action) {
      state.userFavouriteDocs = action.payload;
    },
    getFavouriteDocs(state, action) {
      state.FavouriteDocs = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { handleClearChat,setFilteredWikiData,setSearchedWikiData,updateWikiFilters, handleClearFilter, handleChatFilters, handleSelectedChat,updateCompareDocs,updateLoadingState } =
  slice.actions;



// ----------------------------------------------------------------------

export function getFilterMapping() {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`chat/filter_data`);
      dispatch(slice.actions.setFilterMappingSuccess(response.data));
      dispatch(slice.actions.setWikiData(response.data));
      dispatch(slice.actions.setSearchedWikiData(Object.values(response.data)));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchFavouriteDocs() {
  return async (dispatch) => {
      try {
          const response = await axiosInstance.get('/docs/get_favourite');
          if (response.status === 200 && Array.isArray(response.data)) {
              dispatch(slice.actions.getFavouriteDocs(response.data));
              return { payload: response.data }; // Ensure to return this data for component's consumption
          } else {
              throw new Error('Unexpected response format');
          }
      } catch (error) {
          console.error(error);
          dispatch(slice.actions.hasError(error.toString()));
          return { error: error.toString() }; // Return error for the component to handle
      }
  };
}


export function setFavouriteDoc(title, like) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post('/docs/set_favourite', {
        title,
        like
      });
      dispatch(slice.actions.updateFavouriteStatus(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function fetchUserFavouriteDocs() {
  return async (dispatch) => {
      try {
          const response = await axiosInstance.post('/docs/get_user_favourite');
          dispatch(slice.actions.getUserFavouriteDocs(response.data)); // Ensure this only contains the titles
      } catch (error) {
          console.error(error);
          dispatch(slice.actions.hasError(error));
      }
  };
}


// ----------------------------------------------------------------------
