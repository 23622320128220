import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { useDispatch } from 'react-redux';
import axiosInstance from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { GetProfileImg } from '../redux/slices/profile';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  message: ''
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { message } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      user: null,
      message
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        // if (accessToken && isValidToken(accessToken)) {
        if (accessToken) {
          setSession(accessToken);
          reduxDispatch(GetProfileImg());

          // const response = await axios.get('/api/account/my-account');
          // const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: null
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        // console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    const response = await axiosInstance.post('auth/login', formData);

    const accessToken = response.data?.auth_token;
    const user = response.data?.user_id;
    if (response?.data?.error) {
      return response;
    }

    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const register = async (email, password, repeatPassword, firstName, lastName, jobTitle) => {
    const formData = new FormData();
    formData.append('first_name', firstName);
    formData.append('surname', lastName);
    formData.append('job_title', jobTitle);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('confirm_password', repeatPassword);
    const response = await axiosInstance.post('auth/signup', formData);
    return response;
    // dispatch({
    //   type: 'REGISTER',
    //   payload: {
    //     message
    //   }
    // });
  };

  const logout = async () => {
    const response = await axiosInstance.get('auth/signout');
    setSession(null);
    dispatch({ type: 'LOGOUT' });
    return response;
  };

  const resetPassword = async (email) => {
    const formData = new FormData();
    formData.append('email', email);
    const response = await axiosInstance.post('auth/reset_password', formData);
    return response;
  };

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
