import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosInstance from '../../utils/axios';
import { setSession } from '../../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isProfileLoading: false,
  isEditMode: false,
  profileData: null,
  isLoading: false,
  profileImg: null,
  error: ''
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // start loading
    startProfileLoading(state) {
      state.isProfileLoading = true;
    },
    // Edit btn
    handleEditProfile(state, action) {
      state.isEditMode = action.payload;
    },
    // GET profile
    getProfileSuccess(state, action) {
      state.isProfileLoading = false;
      state.profileData = action.payload;
    },
    // GET profile
    getProfileImgSuccess(state, action) {
      state.isProfileLoading = false;
      state.profileImg = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { handleEditProfile } = slice.actions;

// ----------------------------------------------------------------------

export function UpdateProfile(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startProfileLoading());
    try {
      const response = await axiosInstance.post(`account/update`, body);
      dispatch(slice.actions.getProfileSuccess(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
      return error;
      // dispatch(slice?.actions?.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function GetProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startProfileLoading());
    try {
      const response = await axiosInstance.get(`account/info`);
      dispatch(slice.actions.getProfileSuccess(response.data));
    } catch (error) {
      dispatch(slice?.actions?.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function UploadrofileImg(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startProfileLoading());
    try {
      const formData = new FormData();
      formData.append('file', body.file);
      // const response =
      await axiosInstance.post(`account/upload/image`, formData);
      dispatch(slice.actions.getProfileImgSuccess(URL.createObjectURL(body.file)));
    } catch (error) {
      dispatch(slice?.actions?.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function GetProfileImg() {
  return async (dispatch) => {
    dispatch(slice.actions.startProfileLoading());
    try {
      const response = await axiosInstance.get(`account/image`);
      console.log('caled', response?.data[0]);
      dispatch(slice.actions.getProfileImgSuccess(response?.data && response?.data[0]));
      return response;
    } catch (error) {
      dispatch(slice?.actions?.hasError(error));
    }
  };
}
