import React, { useState, useEffect } from 'react';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';

// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
// import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import CookieConsent from "react-cookie-consent";
import CookieConsentDialog from './CookieConsentDialog';

const loadClarityScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.innerHTML = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${process.env.REACT_APP_CLARITY_PROJECT_ID}");
  `;
  document.head.appendChild(script);
};


const App = () => {
  const { isInitialized } = useAuth();
  
  const [cookieConsentOpen, setCookieConsentOpen] = useState(true);

   useEffect(() => {
     if (document.cookie.includes('clarity_consent=true')) {
       setCookieConsentOpen(false);
       loadClarityScript();
     }
   }, []);

   const handleCookieConsentAccept = () => {
     const expirationDate = new Date();
     expirationDate.setDate(expirationDate.getDate() + 150);
     // Set the cookie to indicate that the user has accepted the cookie consent
     document.cookie = `clarity_consent=true; expires=${expirationDate.toUTCString()};`;
     setCookieConsentOpen(false);
     loadClarityScript();
   };

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <ScrollToTop />
              <GoogleAnalytics />
              <CookieConsentDialog
                open={cookieConsentOpen}
                onClose={() => setCookieConsentOpen(false)}
                onAccept={handleCookieConsentAccept}
              />
              <Router />
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
};

export default App;