import PropTypes from 'prop-types';
import CookieConsent from "react-cookie-consent";

import { styled, Box, Typography, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

// Styled components
const CookieConsentDialogStyle = styled('div')(({ theme }) => ({
  padding: '20px',
  backgroundColor: '#F6F6F6',
  // color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#4e503b',
  fontSize: '13px',
  backgroundColor: '#fff',
  border: 'none',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
  marginTop: '20px',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));

// PropTypes for the component
CookieConsentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
};

// Main component
export default function CookieConsentDialog({ open, onClose, onAccept }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box>
          <Typography variant="h6">Cookie Consent</Typography>
        </Box>
      </DialogTitle>
      <DialogContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 0px 0 0px',
          }}>
        <CookieConsentDialogStyle>
          <Typography variant="body1">
            We use cookies to improve your user experience, track performance, and enhance our products. By clicking "Agree", you agree to our use of cookies. For more information, please read our{' '}
            <a href="https://innex.ai/privacy-policy" style={{ color: '#4e503b' }} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
          </Typography>
          <Box>
            <StyledButton onClick={onAccept}>Agree</StyledButton>
            {/* <StyledButton onClick={onClose} sx={{ ml: 2 }}>
              Cookie Settings
            </StyledButton> */}
          </Box>
        </CookieConsentDialogStyle>
      </DialogContent>
    </Dialog>
  );
}