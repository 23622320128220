import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, Button, styled } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 550,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(4, 0, 6, 4),
  borderRadius: '10px',
  background: 'linear-gradient(332.37deg, #47CCBB -12.09%, #194D92 81.83%)'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 550,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle title="Login | Innex.ai">
      <MHidden width="mdDown">
        <SectionStyle>
          <Box sx={{ px: 5, mt: 10, mb: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src="/static/static_logo.svg" alt="static_logo" />
          </Box>
          <Box
            sx={{
              height: '90%',
              width: '90%',
              margin: 'auto',
              img: {
                height: '100%',
                width: '100%'
              }
            }}
          >
            <img src="/static/illustrations/illustration_login.svg" alt="login" />
          </Box>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="md">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Log In to innex.ai
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Access your copilot for hospital facilities.
              </Typography>
            </Box>

            {/* <Tooltip title={capitalCase(method)}>
              <Box component="img" src={`/static/auth/ic_${method}.png`} sx={{ width: 32, height: 32 }} />
            </Tooltip> */}
          </Stack>

          {/* {method === 'firebase' || <AuthFirebaseSocials />} */}

          {/* <Alert severity="info" sx={{ mb: 3 }}>
            Use email : <strong>demo@gmail.com</strong> / password :<strong>&nbsp;demo1234</strong>
          </Alert> */}

          {method !== 'auth0' ? (
            <LoginForm />
          ) : (
            <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
              Login
            </Button>
          )}

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Register
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
